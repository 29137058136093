@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@1,600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@media only screen and (min-width: 60px) {

    .formulario{
        width:100%;
        height: 25px;
        margin-bottom: 5px;
        margin-top: 5px;
        border: 1px gray solid;
        border-radius: 12px;
        font-family: 'Roboto', sans-serif;
    }
    
    .sub-formulario{
        width:100%;
        height: 25px;
        margin-bottom: 5px;
        margin-top: 5px;
        border: 1px gray solid;
        border-radius: 12px;
        font-family: 'Roboto', sans-serif;
    }
    .logo-pago{
        margin-left: 13%;
        width: 70%; 
        height: auto;
        text-align: center;
    }

    .titulo{
        color: "#707070";
        margin-left: 25%; 
    }
}

@media only screen and (min-width: 767px) {


    .formulario{
        width:100%;
        height: 35px;
        margin-bottom: 5px;
        margin-top: 5px;
        border: 1px gray solid;
        border-radius: 12px;
        font-family: 'Roboto', sans-serif;
        font-size: 1.4rem;
    }
    
    .sub-formulario{
        width:100%;
        height: 35px;
        margin-bottom: 5px;
        margin-top: 5px;
        border: 1px gray solid;
        border-radius: 12px;
        font-family: 'Roboto', sans-serif;
        font-size: 1.4rem;
    }

    .logo-pago{
        width: 70%; 
        height: auto;
        text-align: center;
    }

    .titulo{
        color: "#707070";
        margin-left: 30%;
        font-size: 2.0rem; 
    }

    .title{
        font-size: 1.5rem;
    }
}

@media only screen and (min-width: 960px) {

}

@media only screen and (min-width: 1500) {

}
